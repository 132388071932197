<template>
  <div class="index-module">
    <a-row :gutter="[16, 16]">
      <a-col :span="6" :sm="12" :md="12" :lg="6" :xl="6">
        <a-card title="会员统计" :bordered="false">
          <template #extra><a href="#">详情</a></template>
          <h2>{{ stat_info.user_num }}</h2>
          <p>今日新增：{{ stat_info.user_num_today }}</p>
        </a-card>
      </a-col>
      <a-col :span="6" :sm="12" :md="12" :lg="6" :xl="6">
        <a-card title="文档统计" :bordered="false">
          <template #extra><a href="#">详情</a></template>
          <h2>{{ stat_info.doc_num }}</h2>
          <p>今日新增：{{ stat_info.doc_num_today }}</p>
        </a-card>
      </a-col>
      <a-col :span="6" :sm="12" :md="12" :lg="6" :xl="6">
        <a-card title="资源统计" :bordered="false">
          <template #extra><a href="#">详情</a></template>
          <h2>{{ stat_info.resources_num }}</h2>
          <p>图片：{{ stat_info.resources_num_image }} 视频：{{ stat_info.resources_num_video }}</p>
        </a-card>
      </a-col>
      <a-col :span="6" :sm="12" :md="12" :lg="6" :xl="6">
        <a-card title="浏览量" :bordered="false">
          <template #extra><a href="#">详情</a></template>
          <h2>{{ stat_info.views_num }}</h2>
          <p>今日浏览量：{{ stat_info.views_num_today }}</p>
        </a-card>
      </a-col>
    </a-row>
  </div>
<!--  <div class="index-module">-->
<!--    <a-row type="flex" :gutter="[16, 16]" justify="space-between">-->
<!--      <a-col :span="3" :xs="12" :sm="12" :md="6" :lg="6" :xl="3" v-for="(item, index) in 8" :key="index">-->
<!--        <div class="order-status">-->
<!--          <h2>5</h2>-->
<!--          <p>待付款订单</p>-->
<!--        </div>-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--  </div>-->

  <div class="index-module">
    <a-card title="趋势" :bordered="false">
      <div id="container" />
    </a-card>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import * as Api from "@/views/index/api";
import { Chart } from "@antv/g2";
export default defineComponent({
  components: {},
  setup() {
    const dataForm = reactive({});

    const stat_info = ref({});
    const getInitStat = () => {
      Api.stat(dataForm).then((res) => {
        if (res.status == 200) {
          stat_info.value = res.data;
        }
      });
    };

    const findMaxMin = (data) => {
      let maxValue = 0;
      let minValue = 50000;
      let maxObj = null;
      let minObj = null;
      for (const d of data) {
        if (d.Close > maxValue) {
          maxValue = d.Close;
          maxObj = d;
        }
        if (d.Close < minValue) {
          minValue = d.Close;
          minObj = d;
        }
      }
      return { max: maxObj, min: minObj };
    };

    const getInitChart = () => {
      const data = [
        {
          Date: "04.01.2016",
          Close: 126.12,
        },
        {
          Date: "05.01.2016",
          Close: 125.688,
        },
        {
          Date: "06.01.2016",
          Close: 119.704,
        },
        {
          Date: "07.01.2016",
          Close: 120.19,
        },
        {
          Date: "08.01.2016",
          Close: 121.157,
        },
        {
          Date: "11.01.2016",
          Close: 117,
        },
        {
          Date: "12.01.2016",
          Close: 111.487,
        },
        {
          Date: "13.01.2016",
          Close: 122,
        },
        {
          Date: "14.01.2016",
          Close: 117.76,
        },
        {
          Date: "15.01.2016",
          Close: 114.397,
        },
        {
          Date: "18.01.2016",
          Close: 116.373,
        },
        {
          Date: "19.01.2016",
          Close: 120.547,
        },
        {
          Date: "20.01.2016",
          Close: 113.733,
        },
        {
          Date: "21.01.2016",
          Close: 114.098,
        },
        {
          Date: "22.01.2016",
          Close: 123.833,
        },
        {
          Date: "25.01.2016",
          Close: 125,
        },
        {
          Date: "26.01.2016",
          Close: 124.866,
        },
        {
          Date: "27.01.2016",
          Close: 120.264,
        },
        {
          Date: "28.01.2016",
          Close: 122.296,
        },
        {
          Date: "29.01.2016",
          Close: 124.502,
        },
        {
          Date: "01.02.2016",
          Close: 127.936,
        },
        {
          Date: "02.02.2016",
          Close: 132.513,
        },
        {
          Date: "03.02.2016",
          Close: 129.95,
        },
      ];

      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });

      chart.data(data);
      chart.scale({
        Date: {
          tickCount: 10,
        },
        Close: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("Date", {
        label: {
          formatter: (text) => {
            const dataStrings = text.split(".");
            return dataStrings[2] + "-" + dataStrings[1] + "-" + dataStrings[0];
          },
        },
      });
      chart.line().position("Date*Close");
      // annotation
      const { min, max } = findMaxMin(data);
      chart.annotation().dataMarker({
        top: true,
        position: [max.Date, max.Close],
        text: {
          content: "最大值：" + max.Close,
        },
        line: {
          length: 30,
        },
      });
      chart.annotation().dataMarker({
        top: true,
        position: [min.Date, min.Close],
        text: {
          content: "最小值：" + min.Close,
        },
        line: {
          length: 50,
        },
      });

      chart.render();
    };

    onMounted(() => {
      getInitStat();
      getInitChart();
    });

    return {
      stat_info,
    };
  },
});
</script>
<style scoped>
.index-module {
  margin-bottom: 24px;
}
.commonly-item {
  width: 20%;
  text-align: center;
}
.order-status {
  padding: 40px 0;
  background-color: #fff;
  text-align: center;
}
.order-status:hover {
  background-color: #cdcdcd;
}
</style>
